<template>
	<Popup_confirm
		v-if="mode == 'confirm'"
		@cancel="$emit('cancel')"
		@click="onPersonal"
	>
		<template v-slot:title>{{ $language.mypage.title_personal_confirm}}</template>
		<template
			v-slot:main-txt
		>{{ type_name }}을 위해서는 본인인증을 진행해야 합니다</template>
	</Popup_confirm>
	<PopupLayer
		v-else-if="mode == 'personal'"
	>
		<template
			v-slot:body
		>
			<div class=" size-px-16 radius-20 overflow-hidden">
				<div class="bg-white">
					<div class="pa-20 justify-space-between under-line "><h3>{{ $language.mypage.title_personal_confirm}}</h3><v-icon @click="$emit('cancel')">mdi-close-circle</v-icon></div>
					<div
						class="pa-20"
					>

						<div
							class="font-weight-bold"
						>
							{{ type_name }} 시 <br/>본인확인(최초1회)이 필요합니다
						</div>

						<div
							class="mt-20 size-px-13"
						>
							회원정보는 본인의 동의없이 공개되지 않으며 메타마피아 개인정보 보호정책에 의해 보호받고 있습니다.
						</div>

						<div
							class="btn_area mt-30"
						>
							<button
								@click="onRequest"

								class="btn_l btn_fill_blue"
							> 본인 인증 </button>
						</div>
					</div>
				</div>
			</div>

			<form name="form_chk" method="get">
				<input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
				<input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
				<!--
				<a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
				-->
			</form>
		</template>
	</PopupLayer>

	<Popup_confirm
		v-else-if="mode == 'success'"
		:not_cancel="true"
		@click="$emit('click')"
	>
		<template v-slot:title>본인 인증 완료</template>
		<template
			v-slot:sub-txt
		>
			본인 인증이 완료되었습니다.
		</template>
	</Popup_confirm>

	<Popup_confirm
		v-else
		@click="$emit('cancel')"
		:not_cancel="true"
	>
		<template v-slot:title>본인 인증 처리 실패</template>
		<template
			v-slot:sub-txt
		>
			본인 인증 등록 처리가 실패하였습니다. 본인 인증을 다시 한번 시도해주세요.
		</template>
	</Popup_confirm>
</template>
<script>

import Popup_confirm from "@/view/Layout/PopupConfirm";
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	props: ['user', 'type']
	, components: {PopupLayer, Popup_confirm}
	, name: 'PersonalConfirm'
	, data: function(){
		return {
			mode: 'confirm'
			, disabled: false
			, item: {}
		}
	}
	, computed: {
		type_name: function(){
			let t = '카르텔 개설'
			switch (this.type){
				case 'Wallet':
					t = '지갑 생성'
					break
				case 'SubscribeReport':
					t = '카르텔 정산'
					break
				case 'exchange':
					t = 'MAF 교환'
					break;
			}

			return t
		}
	}
	, methods: {
		setMode: function(mode){
			this.mode = mode
		}
		, niceResult: function(call){
			this.fnPopup(call.items.enc_data)
		}
		, fnPopup: function() {

			window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			setTimeout(() => {

				document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
				document.form_chk.target = "popupChk";
				document.form_chk.EncodeData.value = this.item.crypto_data
				document.form_chk.submit();
			}, 100)
		}
		, messageResult: function(e){
			const call = e.data
			console.log('messageResult', call)
			if(call.success){
				this.$set(this.item, 'payload', call.payload)
				this.$set(this.item, 'certificationInformation', call.payload)
				this.$set(this.item, 'mobilPhoneNumber', call.phone_no)
				this.disabled = true
				this.postConfirm()
			}else{
				this.disabled = false
				this.$emit('alertMsg', 'error', call.resp_message)
			}
		}
		, onPersonal: function(){
			this.mode = 'personal'
		}
		, onRequest: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_personal_confirm_request
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.fnPopup()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_personal_confirm_request
					, data: {
						member_number: this.user.member_number
						, self_certfc_request_number: this.item.self_certfc_request_number
						, self_certfc_info: this.item.payload
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.mode = 'success'
					this.$bus.$emit('updateUser', { key: 'mphone_self_certfc_fg', value: 'Y'})
					setTimeout(() => {
						this.$emit('click')
					}, 100)
				}else{
					this.mode = 'fail'
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.messageResult)
	}
	, created() {
		window.removeEventListener('message', this.messageResult)
		setTimeout(() => {
			window.addEventListener('message', this.messageResult);
			console.log('add')
		}, 100)
	}
	,watch: {

	}
}
</script>